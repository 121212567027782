import { IRecommendations, IRecommendationAdditionalSection, IRecommendationArticle, ImageType } from "./typings/iRecommendations"
import { IVariantChange } from "./typings/iDetailpage"

declare global {
    interface Window { shell: any; }
}
window.shell = window.shell || {};

export class MixAndMatchGridHandler {
    public static instance: MixAndMatchGridHandler;
    private node: HTMLElement;
    private gridContainer: HTMLElement;
    private hiddenContainer: HTMLElement;
    private showcase: HTMLElement;
    private apiUrl: string;
    private expandedMainGroup: number = 1;
    private mainGroupCount3col: number = 0;
    private mainGroupCount2col: number = 0;
    private currentMcv: string;
    private imageRefs: string[] = [];
    private lastSelectableImage: number = 1;
    private expanderWasActivatedByKeyboard: boolean = false;

    constructor(node: HTMLElement) {
        if (node.attributes['initialized']) {
            return;
        }
        this.node = node;
        this.apiUrl = this.node.dataset.apiurl;
        this.gridContainer = this.node.querySelector<HTMLElement>('.grid-container');
        this.hiddenContainer = this.node.querySelector<HTMLElement>('.hidden-container');
        this.showcase = this.node.querySelector<HTMLElement>('.origin-showcase');
        this.initialize();
    }

    private initialize(): void {
        this.node.attributes['initialized'] = true;
        if (window.shell) {
            window.shell.subscribeTo('ESPP1.Detailpage.VariantChange.Public',
                (payload: IVariantChange) => {
                    let newMcv: string = payload.masterArticleNo + '-' + payload.colorCode;
                    if (newMcv === this.currentMcv) {
                        return;
                    }
                    this.buildView(newMcv);
                }, 'ESPP1.Detailpage.VariantChange.Public');
        }
        let mcv = this.getCurrentMcv();
        if (!mcv) {
            return;
        }
        this.buildView(mcv);
    }

    private getCurrentMcv(): string {
        if (this.node.dataset.masterarticleno && this.node.dataset.colorcode) {
            return this.node.dataset.masterarticleno + '-' + this.node.dataset.colorcode;
        }
        return '';
    }

    private buildView(mcv: string): void {
        this.currentMcv = mcv;
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `${this.apiUrl}${mcv}`, true);
        xhr.responseType = 'json';
        xhr.onload = () => {
            var status = xhr.status;
            if (status === 200) {
                this.renderView(xhr.response);
            } else {
                this.removeView();
            }
        };
        xhr.send();
    }

    private renderView(recommendations: IRecommendations): void {
        if (!recommendations.articles.length) {
            this.removeView();
            return;
        }
        const originImageUrl: string =
            recommendations.assetBaseAddress + 'TileOrigin/' + recommendations.targetArticle.image;
        if (window.shell) {
            window.shell.publishTo('ESPP2.MixAndMatch.OnLoaded', { available: true });
            window.shell.publishTo('SCS.Update.FallBackImageUrl', { newFallBackImageUrl: originImageUrl });
        }
        this.imageRefs = [];
        // Start Default Grid
        let newHtml: string = this.openGrid('', 'main', 1);
        newHtml = this.addOriginArticle(newHtml, recommendations.targetArticle.name);
        for (let i = 0; i < recommendations.articles.length; i++) {
            newHtml = this.addCrossArticle(newHtml, recommendations.articles[i], recommendations.assetBaseAddress, i + 1, true, recommendations.itemOrigin);
        }
        newHtml = this.closeGrid(newHtml);
        newHtml = this.renderExpanderButton(newHtml, recommendations.expanderLabel, recommendations.articles.length, 2);
        this.mainGroupCount3col = Math.ceil((recommendations.articles.length - 7) / 9);
        this.mainGroupCount2col = Math.ceil((recommendations.articles.length - 4) / 8);
        this.expandedMainGroup = 1;
        // End Default Grid

        if (recommendations.additionalSections.length) {
            for (let i = 0; i < recommendations.additionalSections.length; i++) {
                const mainTabOrderNumber = 2;
                const tabOrderNumber = mainTabOrderNumber + i + 1;
                newHtml = this.addAdditionalSection(newHtml, recommendations.additionalSections[i], recommendations.assetBaseAddress, recommendations.itemOrigin, tabOrderNumber);
            }
        }
        this.hiddenContainer.appendChild(this.showcase);
        this.showcase.querySelector<HTMLElement>('.fall_back_img')?.setAttribute('src', originImageUrl);
        this.gridContainer.innerHTML = newHtml;
        this.gridContainer.querySelector<HTMLElement>('.origin.item .itemcontent')?.appendChild(this.showcase);
        let expanderButton = this.gridContainer.querySelector<HTMLElement>('.expandercontainer.main .expanderbutton');
        if (expanderButton) {
            expanderButton.addEventListener('click', () => { this.expanderClickCallback('main'); }, { once: false, capture: false, passive: true });
            expanderButton.addEventListener('touchstart', this.fixTouchHoverCallback.bind(this), { once: true, capture: false, passive: true });
        }
        this.gridContainer.querySelectorAll<HTMLElement>('.item .hoverlayer').forEach((linkElement) => { this.initLinkElementHandlers(linkElement); });

        if (document.documentElement?.classList.contains('desktop')) this.addKeyboardNavigationEvents();
    }

    private initLinkElementHandlers(linkElement: HTMLElement): void {
        linkElement.addEventListener('mouseover', this.updateLinkElementCallback.bind(this), { once: true, capture: false, passive: true });
        linkElement.addEventListener('touchstart', this.updateLinkElementCallback.bind(this), { once: true, capture: false, passive: true });
        linkElement.addEventListener('click', this.orderModalLinkClickCallback.bind(this), { once: false, capture: false, passive: false });
    }

    private addKeyboardNavigationEvents(): void {
        // prevent page jump for M&M content
        this.gridContainer.addEventListener('keydown', (event) => { 
            if (event.code === 'Space') event.preventDefault();
        });

        const expanderbutton = this.gridContainer.querySelector<HTMLElement>('.expandercontainer.main .expanderbutton');
        if (expanderbutton) {
            expanderbutton.addEventListener('keydown', (event) => {
                if (event.key === 'Enter' || event.code === 'Space') {
                    this.lastSelectableImage = this.imageRefs.lastIndexOf("");
                    this.expanderWasActivatedByKeyboard = true;
                    expanderbutton.click();                
                }
            });
        }
        
        const linkElements = this.gridContainer.querySelectorAll<HTMLElement>('.cross.item .hoverlayer');
            linkElements.forEach((link) => link.addEventListener('keydown', (event) => {
                if (event.code === 'Space') link.click();        
            })
        );
    }

    private fixTouchHoverCallback(event: Event): void {
        const target: HTMLElement = <HTMLElement>event.currentTarget;
        target.classList.remove('hoverable');
    }

    private removeAllHoverStates(): void {
        this.gridContainer.querySelectorAll<HTMLElement>('.hoverlayer').forEach((linkElement) => {
            linkElement.classList.remove('hover');
        });
    }

    private updateLinkElementCallback(event: Event): void {
        const target: HTMLElement = <HTMLElement>event.currentTarget;
        if (event.type === 'touchstart') {
            target.classList.add('touch');
        }

        if (target.nodeName.toUpperCase() === 'DIV' || target.getAttribute('href').indexOf('itemorigin') > 0) {
            return;
        }

        if (target.getAttribute('href').indexOf('?') > 0) {
            target.setAttribute('href', target.getAttribute('href') + "&itemorigin=" + target.dataset.itemorigin);
        } else {
            target.setAttribute('href', target.getAttribute('href') + "?itemorigin=" + target.dataset.itemorigin);
        }
    }

    private orderModalLinkClickCallback(event: Event): void {
        if (event.cancelable) {
            event.preventDefault();
        }
        let target = event.currentTarget as HTMLElement;

        if (target.classList.contains('touch')) {
            if (!target.classList.contains('hover')) {
                this.removeAllHoverStates();
                target.classList.add('hover');
                return;
            }
        }
        if (!target.dataset.modaltarget) {
            return;
        }

        const isRedesignOrderModal = document.querySelector('html')!.classList.contains('RedesignOrderModal');
        let link: string = decodeURIComponent(atob(target.dataset.modaltarget));
        
        if (window.shell) {
            if (isRedesignOrderModal) {
                window.shell.publishTo('ESPP.OrderModal.Open', { 
                    salesArticleNo: target.dataset.salesarticleno!,
                    masterArticleNo: target.dataset.masterarticleno!,
                    colorCode: target.dataset.colorcode!,
                    sizeCode: target.dataset.sizecode!,
                    itemOrigin: target.dataset.itemorigin!
                });
            } else {
                window.shell.publishTo('legacy.modals.openbyajax', link);
            }
        }
    }

    private isMobileShop(): boolean {
        return document.querySelector('html')!.classList.contains('mobileshop');
    }

    private insertImage(itemElement: HTMLElement): void {
        let imageref: number = parseInt(itemElement.getAttribute('data-imageref'));
        if (this.imageRefs[imageref] == "") {
            return;
        }
        let itemContent: Element = itemElement.querySelector('.itemcontent');
        itemContent.insertAdjacentHTML('beforeend', this.imageRefs[imageref]);
        this.imageRefs[imageref] = "";
    }

    private expanderClickCallback(targetSection: string): void {
        try {
            this.expandedMainGroup++;
            this.gridContainer.querySelectorAll<HTMLElement>('.mnm-grid.' + targetSection + ' .col-3-grp-' + this.expandedMainGroup).forEach((itemElement) => {
                this.insertImage(itemElement);
                itemElement.classList.add('col-3-expanded');
            });
            this.gridContainer.querySelectorAll<HTMLElement>('.mnm-grid.' + targetSection + ' .col-2-grp-' + this.expandedMainGroup).forEach((itemElement) => {
                this.insertImage(itemElement);
                itemElement.classList.add('col-2-expanded');
            });
            if (this.expandedMainGroup > this.mainGroupCount3col) {
                const expanderContainer = this.gridContainer.querySelector<HTMLElement>('.expandercontainer.' + targetSection);
                if (expanderContainer) {
                    expanderContainer.classList.add('col-3-hidden');
                    const expanderbutton = expanderContainer.querySelector(".expanderbutton");
                    expanderbutton?.removeAttribute("focusable");
                    expanderbutton?.classList.remove("has-focus", "has-focus-mouse", "has-focus-keyboard");
                }
            }
            if (this.expandedMainGroup > this.mainGroupCount2col) {
                const expanderContainer = this.gridContainer.querySelector<HTMLElement>('.expandercontainer.' + targetSection);
                if (expanderContainer) {
                    expanderContainer.classList.add('col-2-hidden');
                    const expanderbutton = expanderContainer.querySelector(".expanderbutton");
                    expanderbutton?.removeAttribute("focusable");
                    expanderbutton?.classList.remove("has-focus", "has-focus-mouse", "has-focus-keyboard");
                }
            }
            if (this.expanderWasActivatedByKeyboard) {
                // refocus on next loaded image content in viewport
                const nextSelectableImage = this.gridContainer.querySelector(`.mnm-grid.${targetSection} [data-imageref="${this.lastSelectableImage + 1}"] .hoverlayer`);
                if (nextSelectableImage && window.shell) {
                    window.shell.tabNav.focus(nextSelectableImage, 'keyboard');
                }
                this.expanderWasActivatedByKeyboard = false;
            }
        } catch (e) {}
    }

    private renderExpanderButton(html: string, label: string, tilecount: number, tabOrderNumber: number): string {
        if(tilecount <= 4) {
            return html;
        }
        let styles: string = '';
        if (tilecount <= 7) {
            styles += 'col-3-hidden';
        }
        if (tilecount <= 4) {
            styles += ' col-2-hidden';
        }
        html += '<div class="expandercontainer main ' + styles + '" group order="' + tabOrderNumber + '">';
        html += '<div class="expanderbutton hoverable" focusable role="button">' + label +
            '<div class="downarrow"><svg xmlns="http://www.w3.org/2000/svg" width="19.80001" height="9.80057" viewBox="0 0 19.80001 9.80057"><path d="M9.9,9.80057A.90012.90012,0,0,1,9.30186,9.573l-9-8A.90028.90028,0,0,1,1.49815.22733L9.9,7.69608,18.30186.22733A.90028.90028,0,0,1,19.49815,1.573l-9,8A.90011.90011,0,0,1,9.9,9.80057Z"/></svg></div>' +
            '</div>';
        html += '</div>';
        return html;
    }

    private addAdditionalSection(html: string, section: IRecommendationAdditionalSection, imagePath: string, itemOrigin: string, tabOrderNumber: number): string {
        html += '<div class="sectionheadline">' +
            '<p>' + section.sectionLabel.replace(/&lt;|&gt;/g,
                tag => ({ '&lt;': '<', '&gt;': '>' }[tag] || tag)) + '</p>' +
            '</div>';
        html = this.openGrid(html, section.additionalSectionType.toString(), tabOrderNumber);
        for (let i = 0; i < section.articles.length; i++) {
            if (i > 2) {
                break;
            }
            html = this.addCrossArticle(html, section.articles[i], imagePath, i + 1, false, itemOrigin);
        }
        return this.closeGrid(html);
    }

    private removeView(): void {
        if (window.shell) {
            window.shell.publishTo('ESPP2.MixAndMatch.OnLoaded', { available: false });
        }
        this.hiddenContainer.appendChild(this.showcase);
        this.gridContainer.innerHTML = '';
    }

    private openGrid(html: string, section: string, tabOrderNumber: number): string {
        return html + '<div class="mnm-grid ' + section + '" group order="' + tabOrderNumber + '">';
    }

    private closeGrid(html: string): string {
        return html + '</div>';
    }

    private addOriginArticle(html: string, name: string): string {
        html +=
            '<div class="origin item">' +
                '<div class="itemcontent">' +
                    '<div class="hoverlayer">' +
                        '<p>' + name + '</p>' +
                    '</div>' +
                    '<div class="plus-icon">+</div>' +
            '</div>' +
            '</div>';
        return html;
    }

    private addCrossArticle(html: string, article: IRecommendationArticle, imagePath: string, index: number, expandableGroups: boolean, itemOrigin: string): string {
        let fallbackimageurl: string = imagePath;
        let srcset: string = '';
        let imgurl: string = '';
        try {
            imgurl = article.image.url.replace('.png', '.jpg');
        }
        catch (e) {
            imgurl = article.image.url;
        }
        if (article.image.type === ImageType.Action) {
            fallbackimageurl += 'TileMediumAction/';
            srcset += imagePath + 'TileSmallAction/' + imgurl + ' 180w';
            srcset += ', ' + imagePath + 'TileMediumAction/' + imgurl + ' 340w';
            srcset += ', ' + imagePath + 'TileBigAction/' + imgurl + ' 680w';
        } else {
            fallbackimageurl += 'TileMedium/';
            srcset += imagePath + 'TileSmall/' + imgurl + ' 180w';
            srcset += ', ' + imagePath + 'TileMedium/' + imgurl + ' 340w';
            srcset += ', ' + imagePath + 'TileBig/' + imgurl + ' 680w';
        }
        fallbackimageurl += imgurl;

        let classes: string = 'cross item';
        let lazyload: boolean = true;
        if (expandableGroups) {
            classes += ' col-3-grp-';
            if (index > 7) {
                let grp: number = Math.floor((index - 8) / 9) + 2;
                classes += grp.toString();
            } else {
                classes += '1';
                lazyload = false;
            }

            classes += ' col-2-grp-';
            if (index > 4) {
                let grp: number = Math.floor((index - 5) / 8) + 2;
                classes += grp.toString();
            } else {
                classes += '1';
                lazyload = false;
            }
        } else {
            lazyload = false;
            classes += ' col-3-grp-1';
            classes += ' col-2-grp-';
            if (index > 2) {
                classes += '2';
            } else {
                classes += '1';
            }
        }

        html +=
            '<div class="' + classes + '" data-imageref="' + index + '">' +
                '<div class="itemcontent">' +
                    '<a class="hoverlayer" focusable data-modaltarget="' + article.quickOrderModalLink + '" href="' + article.detailPageLink + '" data-itemorigin="' + itemOrigin + '" data-salesarticleno="' + article.salesArticleNo + '" data-masterarticleno="' + article.masterArticleNo + '" data-colorcode="' + article.colorCode + '" data-sizecode="' + article.sizeCode + '">' +
                        '<p>' + article.description + '</p>' +
                    '</a>';
        let imagehtml: string = '<img src="' + fallbackimageurl + '"' +
            ' srcset="' + srcset + '"' +
            ' sizes="((min-width: 1920px) and (min-resolution: 3dppx)) 680px, (min-width: 410px) 340px, 180px"' +
            ' alt="' + article.description + '" />';

        if (lazyload) {
            this.imageRefs[index] = imagehtml;
        } else {
            this.imageRefs[index] = "";
            html += imagehtml;
        }

        html += '</div>' +
            '</div>';
        return html;
    }
}