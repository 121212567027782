/// <reference path='../typings/message-broker.d.ts'/>
/// <reference path='../typings/event-aggregator.d.ts'/>
/// <reference path='ajax-loader-request.ts'/>

namespace AAS {
    export class AjaxLoader implements IAjaxLoader {
        private static _instance: IAjaxLoader;
        private readonly ajaxLoader: IAjaxLoaderRequest;

        private constructor() {
            this.ajaxLoader = new AAS.AjaxLoaderRequest(this.getApiUrl(), this.isMobileShop());

            //
            // PDP triggers AAS.update events on page load, variant change
            //
            window.shell.subscribeTo('AAS.update', (payload) => { this.update(payload); }, 'AAS.update');
        }

        static get instance() {
            return this._instance || (this._instance = new this());
        }

        private getApiUrl(): string {
            return document.querySelector<HTMLElement>('.scope-aas').dataset.apiurl;
        }

        private isMobileShop(): boolean {
            return document.querySelector('html').classList.contains('mobileshop');
        }

        private queryAas(content?: string): NodeList {
            let queryString = '.scope-aas'; 

            if (content) {
                queryString += `[data-content="${content}"]`;
            }

            return document.querySelectorAll(queryString);
        }    

        async update(event: CustomEvent): Promise<void> {
            const savKeyToLoad = event.detail.savKeyToLoad;
            const targetContent = event.detail.content;

            const scopesToUpdate = this.queryAas(targetContent);
            const scopesToUpdateArray: Array<HTMLElement> = Array.prototype.slice.call(scopesToUpdate);

            for (const scope of scopesToUpdateArray) {
                this.updateOneScope(scope, savKeyToLoad);
            }
        }

        private isTrue(value: string): boolean {
            if (value == null || value.toString().toLowerCase() !== 'true') {
                return false;
            }
                       
            return true;
        }

        private async updateOneScope(scope: HTMLElement, savKeyToLoad: string): Promise<void> {
            const content: string = scope.dataset.content;
            const ajaxResponse = await this.ajaxLoader.requestFor(
                savKeyToLoad,
                content,
                scope.dataset.itemOrigin,
                this.isTrue(scope.dataset.detailsCanBeHidden),
                scope.dataset.displayedInCategoryNavkey || '',
                this.isTrue(scope.dataset.showHeader)
            );

            if (!ajaxResponse) {
                window.shell.publishTo('AAS.updateError', { detail: {content: content, message: `Error loading AAS for ${savKeyToLoad}`, savKeyToLoad: savKeyToLoad}});
                return;
            }    
          
            this.replace(ajaxResponse, content);
            this.initJsComponents(savKeyToLoad, content);

            const payload: AjaxUpdateFinishedPayload = { detail: { queryString: `.scope-aas[data-sav-key="${savKeyToLoad}"][data-content="${content}"]` } };
            window.shell.publishTo('AAS.updateFinished', payload);

            if (content === 'full') {
                const newAas = this.queryAas(content)[0] as HTMLElement;
                window.shell.publishTo('ManualProductComparison.CreateTouchPoints', newAas);

                newAas.querySelectorAll('a[data-quick-order-modal]').forEach(basketLink => {
                    basketLink.addEventListener('click', this.redesignOrderModalLinkCallback.bind(this));
                })
            }

            if (document.documentElement?.classList.contains('desktop')) {
                this.addTabNavigationEvents(content);
                // wait for MPC buttons to render
                setTimeout(() => this.addTabNavigationToMpcButtons(content), 300);
            }
        }

        private redesignOrderModalLinkCallback(event: Event): void {
            const target = event.currentTarget as HTMLElement;
            window.shell.publishTo('ESPP.OrderModal.Open', { 
                salesArticleNo: target.dataset.salesarticleno,
                masterArticleNo: target.dataset.masterarticleno,
                colorCode: target.dataset.colorcode,
                sizeCode: target.dataset.sizecode,
                itemOrigin: target.dataset.itemorigin
            });
        }

        private initJsComponents(savKey: string, content: string): void {
            const newAas = this.queryAas(content)[0] as HTMLElement;
            
            //
            // In Mobile AAS is hidden on PDP initially and opend in modal
            // Only if AAS becomes visible, we can calculate correct heights etc.
            //
            if (this.isMobileShop()) {
                new StickyHeader(savKey, content);
                window.shell.once('AAS.view.shown', () => {
                    new Carousels(newAas);
                    new SubFeaturesEqualHeight(newAas);
                    //
                    // AAS.view.shown is sent by PDP, when AAS is opened in mobile
                    //
                    ActionTracker.triggerOpenDetailsEvent();
                });
            } else {
                new Carousels(newAas);
                new SubFeaturesEqualHeight(newAas);
            }

            const links = newAas.querySelectorAll('a[data-itemorigin]');

            for (const link of Array.prototype.slice.call(links) as Array<HTMLElement>) {
                link.addEventListener('mouseover', function () {
                    if (link.getAttribute('href')?.indexOf('itemorigin') < 0) {
                        const href = link.getAttribute('href');
                        const containsQueryParameter : boolean = href.indexOf('?') > 0;
                        link.setAttribute('href', link.getAttribute('href') + (containsQueryParameter ? '&' : '?')  + 'itemorigin=' + link.dataset.itemorigin);
                    }
                });

                link.addEventListener('touchstart', function () {
                    if (link.getAttribute('href')?.indexOf('itemorigin') < 0) {
                        const href = link.getAttribute('href');
                        const containsQueryParameter: boolean = href.indexOf('?') > 0;
                        link.setAttribute('href', link.getAttribute('href') + (containsQueryParameter ? '&' : '?') + 'itemorigin=' + link.dataset.itemorigin);
                    }
                });
            }
        }

        private addTabNavigationToMpcButtons(content: string): void {
            const newAas = this.queryAas(content)[0] as HTMLElement;
            const mpcButtons = newAas.querySelectorAll('.mpc-scope.scale-tile-container');
            
            mpcButtons.forEach((button: HTMLDivElement) => {
                button.setAttribute('role', 'button');
                button.setAttribute('focusable', 'true');

                button.addEventListener('keydown', (event: KeyboardEvent) => {
                    if (event.key === 'Enter') button.click();
                });
            });
        }

        private addTabNavigationEvents(content: string): void {
            const newAas = this.queryAas(content)[0] as HTMLElement;

            // prevent page jump for AAS content
            newAas.addEventListener('keydown', (event) => {
                if (event.code === 'Space') event.preventDefault();
            });

            // Enter event for divs
            const divsToActivate = ['.view-button', '.separator'];
            divsToActivate.forEach((d) => newAas.querySelectorAll(d).forEach((div: HTMLDivElement) => {
                div.addEventListener('keydown', (event: KeyboardEvent) => {
                    if (event.key === 'Enter') div.click();
                });
            }));

            // Space event for anchors
            const anchorsToActivate = ['.index-product a', '.aas-button.basket a'];
            anchorsToActivate.forEach((a) => newAas.querySelectorAll(a).forEach((anchor: HTMLAnchorElement) => {
                anchor.addEventListener('keydown', (event: KeyboardEvent) => {
                    if (event.code === 'Space') anchor.click();
                });
            }));
        }

        private replace(response: string, content: string): void {
            let newAas: HTMLElement = document.createElement('div') as HTMLElement;
            newAas.innerHTML = response;
            newAas = newAas.querySelector('.scope-aas') as HTMLElement;

            const aasToReplace = document.querySelector(`.scope-aas[data-content="${content}"]`) as HTMLElement

            aasToReplace.parentNode.replaceChild(newAas, aasToReplace);
        }

        // DEBUG only
        triggerAppShellUpdate(savKeyToReplace: string, savKeyToLoad: string, content: string, target: string): void {

            const payload: AjaxPayload =
            {
                detail:
                {
                    savKeyToReplace: savKeyToReplace,
                    savKeyToLoad: savKeyToLoad,
                    content: content,
                    target: target
                }
            };

            window.shell.publishTo('AAS.update', payload);
        }
    }
}

AAS.AjaxLoader.instance; //NOSONAR
